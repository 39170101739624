import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RolePickerService {
  private _roleChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  roleChanged$: Observable<boolean> = this._roleChanged.asObservable();

  private _fetchingData: BehaviorSubject<boolean> = new BehaviorSubject(false);
  fetchingData$: Observable<boolean> = this._fetchingData.asObservable();

  triggerRoleChange(): void {
    this._roleChanged.next(true);
  }

  resetRoleChange(): void {
    this._roleChanged.next(false);
  }

  triggerScreenLoading(): void {
    this._fetchingData.next(true);
  }

  finishScreenLoading(): void {
    this._fetchingData.next(false);
  }
}
