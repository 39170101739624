import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent implements AfterContentChecked {
  @Input() id: string;
  @Input() bindValue: string;
  @Input() inputIcon: string;
  @Input() required: boolean;
  @Input() readOnly: boolean; // Used as disabled option too
  @Input() isLoading: boolean;
  @Input() loadingText: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() control: FormControl;
  @Input() minTermLength: number;
  @Input() virtualScroll: boolean;
  @Input() actionOnChange: unknown;
  @Input() typeToSearchText: string;
  @Input() tooltipLoadingText: string;
  @Input() searchable: boolean = true;
  @Input() typeAhead: Subject<string>;
  @Input() bindLabel: string = 'label';
  @Input() editableSearchTerm: boolean;
  @Input() emptyOptionsMessage: string;
  @Input() isLoadingField: boolean = false;
  @Input() clearSearchOnAdd: boolean = true;
  @Input() hasDisabledOptions: boolean = false;
  @Input() compareWith: (a: any, b: any) => boolean;
  @Input() items: Array<{
    id: string | number;
    label?: string | number;
    name?: string | number;
    leftIcon?: any;
    rightIcon?: any;
  }> = [];

  @Output() closeDropdown: EventEmitter<any> = new EventEmitter<any>();
  @Output() openDropdown: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  onChange(event: any) {
    this.valueChange.emit(event);
  }

  onOpen(event: any) {
    this.openDropdown.emit(event);
  }

  onClose(event: any) {
    this.closeDropdown.emit(event);
  }

  getSelectedLabel(item: { id: string | number } | string | number) {
    if (item[this.bindLabel]) return item[this.bindLabel];
    else {
      // When the object contains attributes but is null.
      if (typeof item === 'object') return null;
      else return item;
    }
  }
}
