import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  GALLERY_CONFIG,
  GalleryComponent,
  GalleryConfig,
  GalleryItem,
  ImageItem,
} from 'ng-gallery';

@Component({
  selector: 'app-event',
  standalone: true,
  imports: [SharedModule, GalleryComponent],
  templateUrl: './event.component.html',
  styleUrl: './event.component.scss',
  providers: [
    {
      provide: GALLERY_CONFIG,
      useValue: {
        autoHeight: true,
        imageSize: 'cover',
      } as GalleryConfig,
    },
  ],
})
export class EventComponent implements OnChanges {
  @Input() event: {
    attributes: {
      key: string;
      value: any;
    }[];
    imageUrls: string[];
  } = {
    attributes: [],
    imageUrls: [],
  };
  images: GalleryItem[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.event.currentValue.imageUrls) {
      this.images = this.event.imageUrls.map(
        (image) =>
          new ImageItem({
            src: image,
          })
      );
    }
  }
}
