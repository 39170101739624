import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { CoreModule } from './core/core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { SystemMaintenanceComponent } from './system-maintenance/system-maintenance.component';
import { SystemMaintenanceGuard } from './system-maintenance/system-maintenance.guard';
import { DashboardService } from './dashboard/dashboard.service';
import { ScheduleModule } from './dashboard/schedule/schedule.module';
import { LogoutComponent } from './logout/logout.component';
import { LoginErrorComponent } from './login-error/login-error.component';
import { ExternalNcrCreationModule } from './external-ncr-creation/external-ncr-creation.module';
import { ExteriorWashOfferResponseModule } from './exterior-wash-offer-response/exterior-wash-offer-response.module';
import { LoginComponent } from './login/login.component';
import { NavigationService } from './shared/navigation/navigation.service';

/**
 * Try clearing Service Worker caches and unregistering any existing workers.
 *
 * If anything is cleared or unregistered, the page is then reloaded to ensure
 * no worker controls the app anymore.
 */
async function clearServiceWorkerAndCache() {
  let shouldReload = false;

  if ('caches' in window) {
    const keys = await caches.keys();
    if (keys.length) {
      await Promise.all(keys.map((key) => caches.delete(key)));
      shouldReload = true;
    }
  }

  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    if (registrations.length) {
      await Promise.all(
        registrations.map((registration) => registration.unregister())
      );
      shouldReload = true;
    }
  }

  if (shouldReload) {
    window.location.reload();
  }
}

const isAutomatedTest = (): boolean =>
  navigator.webdriver === true || /HeadlessChrome/.test(navigator.userAgent);

const enableServiceWorker =
  (environment.production && false) || // Always false for now due to QUAL-16095
  localStorage.getItem('enableSW') === 'true' ||
  isAutomatedTest();

if (!enableServiceWorker) {
  clearServiceWorkerAndCache();
}

@NgModule({
  declarations: [
    AppComponent,
    SystemMaintenanceComponent,
    LogoutComponent,
    LoginComponent,
    LoginErrorComponent,
  ],
  imports: [
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: enableServiceWorker,
    }),
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    NoopAnimationsModule,
    SharedModule,
    ScheduleModule,
    DashboardModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ExternalNcrCreationModule,
    ExteriorWashOfferResponseModule,
  ],
  providers: [SystemMaintenanceGuard, DashboardService, NavigationService],
  bootstrap: [AppComponent],
})
export class AppModule {}
