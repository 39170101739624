import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DynamicTableComponent } from '../../../shared/dynamic-table/dynamic-table.component';
import { UntypedFormControl } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { API } from '@aws-amplify/api';
import { RowComponent } from 'src/app/shared/dynamic-table/row/row.component';
import { EventComponent } from './event/event.component';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-terminal-events',
  standalone: true,
  imports: [SharedModule, DynamicTableComponent, RowComponent, EventComponent],
  templateUrl: './terminal-events.component.html',
  styleUrl: './terminal-events.component.scss',
})
export class TerminalEventsComponent implements OnInit, OnDestroy {
  static route = 'terminal/events';
  terminal: UntypedFormControl;
  isLoadingScreen = false;
  columns = [
    {
      label: 'Event Type',
      fieldName: 'event_type',
      fieldAlias: 'event_type',
      sortable: false,
    },
    {
      label: 'Region',
      fieldName: 'region',
      fieldAlias: 'region',
      sortable: false,
    },
    {
      label: 'Event Time',
      fieldName: 'event_time',
      fieldAlias: 'event_time',
      dateTimeConfig: {
        format: 'MM-dd-yyyy hh:mm a',
        timezone: 'UTC',
      },
      sortable: false,
    },
  ];
  terminalList: string[] | [];
  expandedItemId: string | number;
  rowHeaderData: any[];
  events: any[];
  formattedEvents: any[];
  intervalId: NodeJS.Timeout;
  private terminalSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  loadData() {
    this.isLoadingScreen = true;
    this.getEvents().then((result) => {
      this.events = result;
      this.isLoadingScreen = false;
      this.mountRowHeaderAndData();
    });
  }

  ngOnInit(): void {
    this.terminalList = this.authService.user.roleTerminals || [];
    this.terminal = new UntypedFormControl(this.terminalList[0]);
    this.loadData();
    const ms = 600000; //10 min
    this.intervalId = setInterval(() => {
      this.loadData();
    }, 600000);
    this.terminalSubscription = this.terminal.valueChanges.subscribe((value) => {
      this.events = [];
      this.formattedEvents = [];
      this.rowHeaderData = [];
      this.loadData();
    });
  }

  ngOnDestroy() {
    this.terminalSubscription.unsubscribe();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  handleShowDetailsEvent(item: any) {
    this.expandedItemId = item.id;
  }

  async getEvents() {
    let headers = {
      'Content-Type': 'application/json',
    };

    if (this.authService.user) {
      headers['x-ontrax-identity'] =
        `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`;
    }
    const path = '/terminal-events';
    const httpOptions = {
      headers: headers,
      queryStringParameters: {
        terminalName: this.terminal.value,
      },
    };

    try {
      const events = (await API.get(
        'OnTraxAPI',
        path,
        httpOptions
      )) as Array<any>;
      return events;
    } catch (error) {
      this.toastr.error(
        'Error when fetching terminal events, please contact DCI Team for support.'
      );
      return [];
    }
  }

  formatString(str: string): string {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  formatMetadata(json): { key: string; value: any }[] {
    return Object.entries(json).map(([key, value]) => ({
      key: this.formatString(key),
      value,
    }));
  }

  formatDate(isoString) {
    const date = new Date(isoString);
    return date.getTime() / 1000;
  }

  private mountRowHeaderAndData() {
    this.rowHeaderData = [];
    this.formattedEvents = [];
    const commonKeysForAllEvents = ['event_type', 'region', 'event_time'];
    this.events.forEach((event) => {
      const eventHeaderInfo: any = {};
      let formattedEvent: any = {
        attributes: [],
        imageUrls: '',
      };
      for (const [key, value] of Object.entries(event)) {
        if (key === 'event_time') {
          eventHeaderInfo[key] = {
            label: this.formatString(key),
            value: this.formatDate(value),
          };
        } else if (commonKeysForAllEvents.includes(key)) {
          eventHeaderInfo[key] = {
            label: this.formatString(key),
            value: value,
          };
        }

        if (key === 'metadata') {
          formattedEvent.attributes.push(...this.formatMetadata(value));
        }
        formattedEvent.imageUrls = event.imageUrls;
      }

      if (Object.keys(eventHeaderInfo).length > 0) {
        this.rowHeaderData.push(eventHeaderInfo);
      }

      if (Object.keys(formattedEvent).length > 0) {
        this.formattedEvents.push(formattedEvent);
      }
    });
  }
}
