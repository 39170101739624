// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terminal-event-component div,
.terminal-event-component app-dynamic-table {
  padding: 3vh 0vh;
}
.terminal-event-component .control-section {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  gap: 2vw;
  height: 6vh;
}
.terminal-event-component .control-section .terminal-filter {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.terminal-event-component .control-section .terminal-filter .label {
  width: auto;
  padding: 0px;
  margin: 0px;
  margin-right: 1vw;
}
.terminal-event-component .control-section .terminal-filter app-dropdown {
  width: 50%;
  height: 100%;
}
.terminal-event-component .terminal-filter,
.terminal-event-component app-filter-input {
  width: 100%;
}
.terminal-event-component .terminal-filter {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/terminal/terminal-events/terminal-events.component.scss"],"names":[],"mappings":"AACE;;EAEE,gBAAA;AAAJ;AAGE;EACE,aAAA;EACA,8BAAA;EACA,UAAA;EACA,mBAAA;EACA,QAAA;EACA,WAAA;AADJ;AAGI;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,eAAA;EACA,mBAAA;AADN;AAGM;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;AADR;AAGM;EACE,UAAA;EACA,YAAA;AADR;AAME;;EAEE,WAAA;AAJJ;AAOE;EACE,aAAA;AALJ","sourcesContent":[".terminal-event-component {\n  div,\n  app-dynamic-table {\n    padding: 3vh 0vh;\n  }\n\n  .control-section {\n    display: flex;\n    justify-content: space-between;\n    width: 80%;\n    align-items: center;\n    gap: 2vw;\n    height: 6vh;\n\n    .terminal-filter {\n      display: flex;\n      align-items: center;\n      align-content: center;\n      flex-wrap: wrap;\n      flex-direction: row;\n\n      .label {\n        width: auto;\n        padding: 0px;\n        margin: 0px;\n        margin-right: 1vw;\n      }\n      app-dropdown {\n        width: 50%;\n        height: 100%;\n      }\n    }\n  }\n\n  .terminal-filter,\n  app-filter-input {\n    width: 100%;\n  }\n\n  .terminal-filter {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
