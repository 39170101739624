import { Component } from '@angular/core';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { RolePickerService } from 'src/app/core/services/role-picker.service';

@Component({
  template: '',
})
export class QuicksightDashboardComponent {
  public isLoading: boolean;
  public hasErrors: boolean;
  protected rolePickerService: RolePickerService;

  constructor(rolePickerService?: RolePickerService) {
    this.isLoading = true;
    this.hasErrors = false;
    this.rolePickerService = rolePickerService;
    if (this.rolePickerService) {
      this.trackPageRefetch();
    }
  }

  public onError(): void {
    this.hasErrors = true;
  }

  async loadDashboardContainer(
    quicksightDashboardData,
    quicksightDashboardParameters
  ) {
    try {
      const embeddingContext = await createEmbeddingContext();

      const frameOptions = {
        url: quicksightDashboardData.EmbedUrl,
        container: document.getElementById('dashboard-container'),
        resizeHeightOnSizeChangedEvent: true,
        width: '100%',
        onChange: (changeEvent, metadata) => {
          if (changeEvent.eventLevel === 'ERROR') {
            console.error(
              `Embedding experience failed. ${JSON.stringify(
                changeEvent,
                metadata
              )}`
            );
            this.onError();
          }
        },
      };

      const contentOptions = {
        parameters: quicksightDashboardParameters,
        toolbarOptions: {
          undoRedo: true,
          reset: true,
        },
        onMessage: async (messageEvent, metadata?) => {
          switch (messageEvent.eventName) {
            case 'ERROR_OCCURRED': {
              console.error(
                `Embedding experience messaging failed. ${JSON.stringify(
                  messageEvent,
                  metadata
                )}`
              );
              this.onError();
              break;
            }
          }
        },
      };

      await embeddingContext.embedDashboard(frameOptions, contentOptions);

      this.isLoading = false;
      this.rolePickerService?.finishScreenLoading();
    } catch (error) {
      console.error(error);
      this.hasErrors = true;
    }
  }

  private trackPageRefetch(): void {
    this.rolePickerService.triggerScreenLoading();

    this.rolePickerService.fetchingData$.subscribe((isFetching) => {
      this.isLoading = isFetching;
    });
  }
}
