import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

import { environment } from 'src/environments/environment';

import {
  MenuConfig,
  MenuOption,
  OptionConfig,
  SectionConfig,
  Title,
} from '../../navigation.model';
import { NavigationService } from '../../navigation.service';
import { PwaService } from 'src/app/core/services/pwa.service';
import { WashListComponent } from 'src/app/dashboard/wash-list/wash-list.component';
import { SharedModule } from '../../../shared.module';

@Component({
  selector: 'app-subnav-option',
  standalone: true,
  imports: [MatTooltipModule, SharedModule],
  templateUrl: './subnav-option.component.html',
  styleUrl: './subnav-option.component.scss',
})
export class SubNavOptionComponent implements OnInit {
  @Input() isMobile: boolean;
  @Input() isDesktop: boolean;
  @Input() isPortrait: boolean;
  @Input() parentMenuPath: string;
  @Input() menuConfig: MenuConfig;
  @Input() subMenuConfig: OptionConfig;
  @Input() showMinimalistSubnav: () => boolean;

  @Output() itemChosen = new EventEmitter<SectionConfig | OptionConfig>();

  isSelected: boolean;
  iconColor: string = 'var(--darker-icon-color)';

  constructor(
    private menuService: NavigationService,
    private router: Router,
    private pwaService: PwaService
  ) {}

  ngOnInit(): void {
    this.isSelected = this.checkSelectedMenu();

    this.router.events.subscribe(() => {
      this.isSelected = this.checkSelectedMenu();
    });

    const isInstallOnTraxOption =
      this.subMenuConfig.label === Title.InstallOnTrax;

    if (isInstallOnTraxOption) {
      this.pwaService.appInstalled$.subscribe((isInstalled) => {
        this.subMenuConfig.hidden = isInstalled;
      });

      this.pwaService.beforeInstallPrompt$.subscribe((promptAvailable) => {
        this.subMenuConfig.hidden = !promptAvailable;
      });

      this.pwaService.isInstalling$.subscribe((isInstalling) => {
        this.subMenuConfig.disabled = isInstalling;
      });
    }
  }

  navigateToSubMenu() {
    if (
      !this.parentMenuPath ||
      !this.subMenuConfig ||
      this.subMenuConfig.disabled
    ) {
      return;
    }

    const dashboardPrefix = 'dashboard';
    switch (this.parentMenuPath) {
      case MenuOption.Ncr:
        this.router.navigate([
          `/${dashboardPrefix}/${this.parentMenuPath}/list`,
          this.subMenuConfig.value,
        ]);
        break;

      case WashListComponent.route:
      case MenuOption.OtherTools:
      case MenuOption.Terminal:
      case MenuOption.BulkUpload:
        this.router.navigate([
          `/${dashboardPrefix}/${this.parentMenuPath}`,
          this.subMenuConfig.value,
        ]);
        break;

      case MenuOption.Home:
      case MenuOption.Schedule:
        this.router.navigate([`/${dashboardPrefix}/${this.parentMenuPath}`]);
        break;

      case MenuOption.Settings:
        if (this.subMenuConfig.value.includes(MenuOption.InstallOnTrax)) {
          this.pwaService.promptInstall();
          return;
        } else {
          this.router.navigate([
            `/${dashboardPrefix}/${this.subMenuConfig.value}`,
          ]);
        }
        break;

      case MenuOption.Reports:
      case MenuOption.ShiftLogs:
        if (this.subMenuConfig.value === MenuOption.ReportsPortal) {
          window.location.href = environment.reportsPortal.url;
          break;
        }

        this.router.navigate([
          `/${dashboardPrefix}/${this.subMenuConfig.value}`,
        ]);
        break;

      case MenuOption.Support:
        if (this.subMenuConfig.value === MenuOption.Wiki) {
          window.open(environment.wikiUrl);
        }

        if (this.subMenuConfig.value === MenuOption.Video) {
          window.open(environment.trainingVideoUrl);
        } else if (this.subMenuConfig.value === MenuOption.Terms) {
          this.router.navigate([`/${dashboardPrefix}/${MenuOption.Terms}`]);
        }

        break;
    }
    this.itemChosen.emit(this.subMenuConfig);
  }

  private checkSelectedMenu(): boolean {
    const selectedMenuName = this.menuService.getSelectedMenu();
    let isSameSubMenu = this.subMenuConfig.value === selectedMenuName;

    if (
      selectedMenuName === 'user-notification' ||
      selectedMenuName === 'business-partner-notification'
    ) {
      isSameSubMenu = this.subMenuConfig.value.includes(selectedMenuName);
    }

    if (selectedMenuName === 'canceled' || selectedMenuName === 'completed') {
      const sectionUrl = this.menuService.getMenuSectionThroughUrl(
        this.menuConfig
      );
      if (isSameSubMenu && sectionUrl.value === this.parentMenuPath)
        return true;
      else return false;
    }

    return isSameSubMenu;
  }
}
