import {
  MenuOption,
  OptionConfig,
  SectionConfig,
  Title,
} from './navigation.model';
import { RoleName } from './role-name.enum';
import { UserType } from './user-type.enum';

import { WashListComponent } from '../../dashboard/wash-list/wash-list.component';
import { AccessDeniedComponent } from '../../dashboard/access-denied/access-denied.component';

export const getSectionDict = (): { [key: string]: Partial<OptionConfig> } => {
  const sectionDict: { [key: string]: OptionConfig } = {};

  sectionDict[MenuOption.Logout] = logOutSection;
  sectionDict[MenuOption.Home] = homeSection;
  sectionDict[MenuOption.OtherTools] = otherToolsSection;
  sectionDict[WashListComponent.route] = requestListSection;
  sectionDict[MenuOption.Schedule] = scheduleSection;
  sectionDict[MenuOption.Settings] = settingsSection;
  sectionDict[MenuOption.ShiftLogs] = shiftLogsSection;
  sectionDict[MenuOption.BulkUpload] = bulkUploadSection;
  sectionDict[MenuOption.Support] = supportSection;
  sectionDict[MenuOption.Ncr] = ncrSection;

  for (const option of reportsSection.options) {
    sectionDict[option.value] = option;
  }

  return sectionDict;
};

export const logOutSection: OptionConfig = {
  label: 'Logout',
  icon: 'logout',
  value: MenuOption.Logout,
  visibleByUserType: [UserType.AllUserTypes],
  authorizedRoles: [RoleName.AllRoles],
};

export const pagesWithRolePicker = [
  WashListComponent.route,
  MenuOption.HomeFeed,
  MenuOption.Reports,
  MenuOption.Schedule,
  MenuOption.ShiftLogs,
  MenuOption.BaySettings,
  MenuOption.UserNotification,
  MenuOption.ActivateTerminal,
  MenuOption.VelocitySettings,
  MenuOption.HoursOfOperation,
  AccessDeniedComponent.route,
];

export const homeSection: SectionConfig = {
  label: 'Home',
  icon: 'home',
  value: MenuOption.Home,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: 'Feed',
      icon: 'newspaper',
      value: MenuOption.HomeFeed,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.AllRoles],
    },
  ],
};

export const otherToolsSection: SectionConfig = {
  label: 'Other Tools',
  icon: 'handyman',
  value: MenuOption.OtherTools,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: 'Datalake',
      icon: 'network_intel_node',
      value: MenuOption.OtherToolsDatalake,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.AllRoles],
    },
    {
      label: 'Data Fabric',
      icon: 'graph_5',
      value: MenuOption.OtherToolsDataFabric,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.AllRoles],
    },
    {
      label: 'RPA',
      icon: 'robot_2',
      value: MenuOption.OtherToolsRpa,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.AllRoles],
    },
  ],
};

export const requestListSection: SectionConfig = {
  label: 'Requests',
  icon: 'local_shipping',
  value: WashListComponent.route,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: 'Drafts',
      icon: 'edit_square',
      value: MenuOption.Draft,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Submitted',
      icon: 'send',
      value: MenuOption.Submitted,
      visibleByUserType: [UserType.External],
      authorizedRoles: [RoleName.Customer],
    },
    {
      label: 'New Requests',
      icon: 'new_window',
      value: MenuOption.NewWashRequests,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Pending',
      icon: 'hourglass_pause',
      value: MenuOption.Pending,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Accepted',
      icon: 'star',
      value: MenuOption.Accepted,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Credit Hold',
      icon: 'credit_card_clock',
      value: MenuOption.CreditHold,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Needs Action',
      icon: 'error',
      value: MenuOption.NeedsAction,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'In Progress',
      icon: 'autoplay',
      value: MenuOption.InProgress,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Hold',
      icon: 'front_hand',
      value: MenuOption.Hold,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Completed',
      icon: 'check_circle',
      value: MenuOption.Completed,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Canceled',
      icon: 'block',
      value: MenuOption.Canceled,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Rejected',
      icon: 'cancel',
      value: MenuOption.Rejected,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Expired Requests',
      icon: 'hourglass_disabled',
      value: MenuOption.Expired,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Exterior Wash Offer',
      icon: 'water_drop',
      value: MenuOption.ExteriorWashOffer,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Schneider Portal Completions',
      icon: 'delivery_truck_bolt',
      customIcon: 'schneider-icon.svg',
      value: MenuOption.SchneiderPortalCompletions,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
  ],
};

export const scheduleSection: SectionConfig = {
  label: 'Scheduling',
  icon: 'calendar_month',
  value: MenuOption.Schedule,
  visibleByUserType: [UserType.Internal],
  options: [
    {
      label: 'Schedule',
      icon: 'calendar_month',
      value: MenuOption.Schedule,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
  ],
};

export const settingsSection: SectionConfig = {
  label: 'Settings',
  icon: 'settings',
  value: MenuOption.Settings,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: Title.InstallOnTrax,
      icon: 'install_mobile',
      value: `settings/${MenuOption.InstallOnTrax}`,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.AllRoles],
    },
    {
      label: 'Notification Preferences',
      icon: 'notifications',
      value: `settings/${MenuOption.UserNotification}`,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
    },
    {
      label: 'Company Notifications',
      icon: 'notifications',
      value: `settings/${MenuOption.BusinessPartnerNotification}`,
      visibleByUserType: [UserType.External],
      authorizedRoles: [RoleName.Customer],
    },
    {
      label: 'Terminal Bays',
      icon: 'table_rows',
      value: MenuOption.BaySettings,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager],
    },
    {
      label: 'Terminal Shifts',
      icon: 'search_activity',
      value: MenuOption.HoursOfOperation,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager],
    },
    {
      label: 'Terminal Velocity',
      icon: 'speed',
      value: MenuOption.VelocitySettings,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager],
    },
    {
      label: 'Activate Terminal',
      icon: 'table_eye',
      value: MenuOption.ActivateTerminal,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager],
    },
  ],
};

export const terminalEventsSection: SectionConfig = {
  icon: 'pin_drop',
  label: Title.Terminal,
  value: MenuOption.Terminal,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      icon: 'event_note',
      label: Title.TerminalEvents,
      value: MenuOption.TerminalEvents,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Depot,
        RoleName.RvpDepot,
      ],
    },
  ],
};

export const reportsSection: SectionConfig = {
  icon: 'insert_chart',
  label: 'Reports',
  value: 'reports',
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      icon: 'insert_chart',
      label: 'Wash Metrics',
      value: MenuOption.WashMetrics,
      visibleByUserType: [UserType.External],
      authorizedRoles: [RoleName.Customer],
    },
    {
      icon: 'finance',
      label: 'Lean Daily Management',
      value: MenuOption.LeanDailyManagementReport,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
    {
      icon: 'finance',
      label: 'Terminal Reports',
      value: MenuOption.Reports,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager],
    },
    {
      icon: 'local_shipping',
      label: 'Terminal Scorecard - Tank Wash & IBC',
      value: MenuOption.TerminalScorecardWash,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
    {
      icon: 'warehouse',
      label: 'Terminal Scorecard - Depot & Transportation',
      value: MenuOption.TerminalScorecardDepot,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
    {
      icon: 'factory',
      label: 'Terminal Scorecard - Industrial Services',
      value: MenuOption.TerminalScorecardIndustrial,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
    {
      icon: 'train',
      label: 'Terminal Scorecard - Rail & Specialty',
      value: MenuOption.TerminalScorecardRail,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
    {
      icon: 'recycling',
      label: 'Waste Management',
      value: MenuOption.EhsWasteManagement,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
    {
      icon: 'finance',
      label: 'Reports Portal',
      value: MenuOption.ReportsPortal,
      visibleByUserType: [UserType.Internal],
      authorizedRoles: [RoleName.Manager, RoleName.Depot, RoleName.RvpDepot],
    },
  ],
};

export const shiftLogsSection: SectionConfig = {
  icon: 'overview',
  label: 'Shift Logs',
  value: MenuOption.ShiftLogs,
  visibleByUserType: [UserType.Internal],
  options: [
    {
      icon: 'overview',
      label: 'Terminal Shift Logs',
      value: MenuOption.ShiftLogs,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
      ],
      visibleByUserType: [UserType.Internal],
    },
  ],
};

export const bulkUploadSection: SectionConfig = {
  label: 'Bulk Upload',
  icon: 'cloud_upload',
  value: MenuOption.BulkUpload,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: 'Download Template',
      icon: 'file_save',
      value: MenuOption.BulkUploadDownloadTemplate,
      authorizedRoles: [RoleName.AllRoles],
      visibleByUserType: [UserType.AllUserTypes],
    },
  ],
};

export const supportSection: SectionConfig = {
  label: 'Support',
  icon: 'help',
  value: 'support',
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: 'User Guide',
      icon: 'book_5',
      value: MenuOption.Wiki,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Depot,
        RoleName.RvpDepot,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Customer Training Video',
      icon: 'videocam',
      value: MenuOption.Video,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Customer,
      ],
      visibleByUserType: [UserType.AllUserTypes],
    },
    {
      label: 'Terms and Conditions',
      icon: 'contract',
      value: MenuOption.Terms,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.AllRoles],
    },
  ],
};

export const ncrSection: SectionConfig = {
  label: 'NCR',
  icon: 'problem',
  value: MenuOption.Ncr,
  visibleByUserType: [UserType.AllUserTypes],
  options: [
    {
      label: 'Opened',
      icon: 'note_add',
      value: MenuOption.NcrOpened,
      visibleByUserType: [UserType.AllUserTypes],
      authorizedRoles: [RoleName.Customer, RoleName.RvpDepot, RoleName.Manager],
    },
    {
      label: 'In Analysis',
      icon: 'quick_reference',
      value: MenuOption.NcrInAnalysis,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Depot,
        RoleName.RvpDepot,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Waiting for Approval',
      icon: 'hourglass_pause',
      value: MenuOption.NcrWaitingForApproval,
      authorizedRoles: [
        RoleName.Manager,
        RoleName.CSC,
        RoleName.Ticket,
        RoleName.Cleaner,
        RoleName.Depot,
        RoleName.RvpDepot,
      ],
      visibleByUserType: [UserType.Internal],
    },
    {
      label: 'Completed',
      icon: 'check_circle',
      value: MenuOption.NcrCompleted,
      authorizedRoles: [RoleName.AllRoles],
      visibleByUserType: [UserType.AllUserTypes],
    },
    {
      label: 'Canceled',
      icon: 'block',
      value: MenuOption.Canceled,
      authorizedRoles: [RoleName.AllRoles],
      visibleByUserType: [UserType.AllUserTypes],
    },
  ],
};
