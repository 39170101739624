// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Using Invoke URL while domain is not created
  baseUrl: 'https://api.dev.ontrax.website/portal',
  settingsBaseUrl: 'https://api.dev.ontrax.website/warehouse',
  searchBaseUrl: 'https://api.dev.ontrax.website/search',
  region: 'us-west-2',
  elasticsearchBaseUrl:
    'https://search-quala-pe2xqduwdc6wddn76rqbuttoqu.us-west-2.es.amazonaws.com',
  elasticsearchIndice: 'dev_wash_request',
  elasticsearchIndiceNcr: 'ncr',
  openBravoBaseUrl: 'https://obtraining.quala.us.com/openbravo/',
  publicAppSyncAPI:
    'https://nfmlywsg6rdnvlwfd7rc5dlyge.appsync-api.us-west-2.amazonaws.com/graphql',
  amplifyConfig: {
    Auth: {
      identityPoolId: 'us-west-2:9d885f33-d834-49b3-87ee-7e8005695417',
      userPoolId: 'us-west-2_oom6rbJeg',
      userPoolWebClientId: '16d590gtmv7p4h0gjqhbrackid',
      cookieStorage: {
        domain: window.location.hostname.includes('localhost')
          ? 'localhost'
          : 'ontrax.website',
        secure: true,
        path: '/',
        expires: 365,
      },
    },
    API: {
      endpoints: [
        {
          name: 'AuthAPI',
          endpoint: 'https://api.dev.ontrax.website/auth',
        },
        {
          name: 'PortalAPI',
          endpoint: 'https://api.dev.ontrax.website/portal',
        },
        {
          name: 'OnTraxAPI',
          endpoint: 'https://api.dev.ontrax.website/ontrax',
        },
        {
          name: 'WarehouseAPI',
          endpoint: 'https://api.dev.ontrax.website/warehouse',
        },
        {
          name: 'ReportsAPI',
          endpoint: 'https://api.dev.ontrax.website/reports',
        },
        {
          name: 'ShiftLogsAPI',
          endpoint: 'https://api.dev.ontrax.website/shift-logs',
        },
        {
          name: 'SearchAPI',
          endpoint: 'https://api.dev.ontrax.website/search',
        },
        {
          name: 'Elasticsearch',
          endpoint:
            'https://search-quala-pe2xqduwdc6wddn76rqbuttoqu.us-west-2.es.amazonaws.com',
          service: 'es',
        },
        {
          name: 'NotificationPreferencesAPI',
          endpoint: 'https://api.dev.ontrax.website/notification-preferences',
        },
        {
          name: 'NonConformityReportAPI',
          endpoint: 'https://api.dev.ontrax.website/ncr',
        },
        {
          name: 'OnTraxAPI',
          endpoint: 'https://api.dev.ontrax.website/ontrax',
        },
        {
          name: 'ExteriorWashOfferAPI',
          endpoint: 'https://api.dev.ontrax.website/exterior-wash-offer',
        },
        {
          name: 'DataSyncAPI',
          endpoint: 'https://api.dev.ontrax.website/data-sync',
        },
      ],
    },
    aws_appsync_graphqlEndpoint:
      'https://2m3i2sfeivfg5lryw57iw4lmeq.appsync-api.us-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AWS_IAM',
    aws_appsync_apiKey: 'null',
    region: 'us-west-2',
    bucket: 'dev-quala-portal-authenticated-user-upload',
  },
  systemMonitor: {
    baseUrl: 'https://api.dev.ontrax.website/system',
    apiKeyName: 'dev-quala-system-monitor-api-key',
    apiKeyValue: 'IE1GwfMQX07Wt4A3Jjpke8oJrS9tiSFV6vwhJSl6',
  },
  reports: {
    baseUrl: 'https://api.dev.ontrax.website/reports',
    reportIds: {
      shiftLogs: '6aac62f5-78ea-4b3f-98c3-3fb3f5b5bebf',
      terminalReports: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      customerWashMetrics: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      ehsWasteManagement: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      leanDailyManagement: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      terminalScorecardDepot: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      terminalScorecardIndustrial: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      terminalScorecardRail: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      terminalScorecardWash: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
    },
  },
  bulkTemplate: {
    downloadUrl:
      'https://prd-quala-wash-template.s3-us-west-2.amazonaws.com/quala-ontrax-bulk-upload-template.xlsx',
  },
  gtmIdentification: 'GTM-W2QM6DQ',
  wikiUrl: 'https://sites.google.com/a/quala.us.com/quala-ontrax-wiki/',
  keycloak: {
    provider: 'logintraining.quala.us.com/auth/realms/master',
    issuer: 'https://logintraining.quala.us.com/auth/',
    realm: 'master',
    clientId: 'ontrax',
  },
  trainingVideoUrl:
    'https://youtube.com/playlist?list=PLMMT00bdm-ENZStzynM3_KlE1oekuwgWD',
  hotjar: {
    siteId: 3424821,
    version: 6,
  },
  reportsPortal: {
    url: 'https://dev.reports.ontrax.website',
  },
  cognito: {
    domainUrl: 'https://dev-ontrax.auth.us-west-2.amazoncognito.com',
    tokenUrl:
      'https://dev-ontrax.auth.us-west-2.amazoncognito.com/oauth2/token',
    redirectUri: `${window.location.origin}/cognito-callback`,
    logoutUri: window.location.origin,
  },
  redirectToNewLoginPage: true,
  allowedIframeOrigins: [
    'http://localhost:4201',
    'https://dev.reports.ontrax.website',
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
